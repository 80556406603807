.container {
  .title {
    font-size: 18px;
    margin-top: 20px;



      display: inline-block; 
      color: #fff; 
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  font-weight: bold;


  }

  .footer {
    display: flex;
    gap: 16px;
  }

  .showFilterLink {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    color: rgb(246, 174, 45);
  }
}




.full {

  width: 100%;

}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;
  font-weight: bold;
  color: #ddd;
  text-shadow: 0 0 16px rgba(130, 143, 255, 0.5);
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
  display: flex;
  padding-bottom: 15px;
}

.formContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
}

.inputField {
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 3px;
  padding: 8px 12px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
  outline: none;
  width: 100%;
  height: 15px;
  border: solid 1px #464444 !important;
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  box-shadow: none;
  margin: 0 !important;
  
  &::placeholder {
    color: #999;
    font-size: 12px;
  }
}

.saveButton {
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 3px 12px;
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border: solid 1px #464444;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #333;
  }
}