.autoBuyContainer {


  
  background-color: #000;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
    min-height: 100vh;



  .title {
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
      min-height: 100vh;

    text-shadow: rgb(1 255 221) 0px 0px 16px;
    font-weight: bold;
  }

  .formContainer {
    display: flex;
    align-items: center;
    gap: 10px; // Space between input fields
    margin-top: 20px;
  }

  .footer {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }

  .showFilterLink {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    color: rgb(246, 174, 45);
  }
}

.toggleInput {
  display: none;
}

.toggleSwitch {
  display: inline-block;
}

.toggleLabel {
  display: flex;
  align-items: center;
  width: 45px;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}

.toggleIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 5px;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.toggleInput:checked + .toggleLabel {

background-color: #4cfaff;
box-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.toggleInput:checked + .toggleLabel .toggleIcon {
  left: 26px;
}


.inputField {
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 3px;
  padding: 8px 12px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
  outline: none;
  width: 100%;
  height: 12px;
  border: solid 1px #464444 !important;
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  box-shadow: none;
  margin: 0 !important;

  &::placeholder {
    color: #999;
    font-size: 12px;
  }
}



.settingsContainer {
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
}

.value {
  font-size: 13px;
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */


}

.settingsCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.settingsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;


}

  .profileName {
  margin-bottom: 0 !important;
} 

.settingsTitle {
  font-size: 13px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */

}

.settingsContainer {
  color: #ddd;
  border-radius: 8px;
}

.settingsRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}


.inputField2 {
  background-color: #1a1a1a;
  border-radius: 3px;
  padding: 3px 5px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;

  outline: none;
  width: 60px;
  border: solid 1px #464444 !important;
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  box-shadow: none;
  margin: 0 !important;
text-align: right;
  &::placeholder {
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    opacity: 0.3;
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
}

.inputField {
  width: 100%;
  border: none;
  padding: 8px 12px;
  font-family: 'Proto Mono', monospace;
  font-size: 12px;
  outline: none;
  color: #d0e1ff;
  border: solid 1px #5e6c8e !important;
  text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6);
  background: #1a1a1a;
padding: 10px !important;
    margin-bottom: 15px;
    /* padding-bottom: 15px; */
    padding: 5px 5px;
border-radius: 3px;
      &::placeholder {
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    opacity: 0.3;
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
    font-size: 12px;
  }

  
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.saveButton {
    width: 100%;
    padding: 10px 10px;
    font-size: 13px;
    color: #1c1c1c;
    background-color: #789fff;
    border: none;
    border-radius: 3px;
margin-bottom: 5px;
    cursor: pointer;
    font-family: "Proto Mono", monospace;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(100, 150, 255, 0.8), 0 0 6px rgba(150, 200, 255, 0.6); /* Pure blue glow */
    box-shadow: 0 0 10px rgba(100, 150, 255, 0.8), 0 0 16px rgba(150, 200, 255, 0.6); /* Updated shadow */
   
}
