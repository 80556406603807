.container {
  background-color: #000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  gap: 20px;
}

.title {
  border-bottom: solid 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    position: relative;
    top: 6px;
    padding-right: 15px;
  }
}

.full {

  width: 100%;

}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileCard:first-child {
  margin-top: 15px;
}
.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */


}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
}

.profileRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.value {
  font-size: 13px;
  color: #fff;

  &.highlight {
    color: #28c0ff;
  }

  &.bag {
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */

  }
}

.settingsRow {
  display: flex;
  align-items: center; /* Vertically center align the items */
  justify-content: space-between; /* Space out the elements */
  padding: 8px 0; /* Spacing within each row */
}



.toggleInput {
  display: none;
}

.toggleSwitch {
  display: inline-block;
}

.toggleLabel {
  display: flex;
  align-items: center;
  width: 45px;
  height: 20px;
  background-color: #ccc;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.1s ease, box-shadow 0.1s ease;
}

.toggleIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 5px;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.toggleInput:checked + .toggleLabel {

background-color: #4cfaff;
box-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.toggleInput:checked + .toggleLabel .toggleIcon {
  left: 26px;
}

.value {
  font-size: 13px;
    color: #aecbff; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */


}

.profileOption {
  background-color: rgb(77, 79, 96);
  border-radius: 5px;
  padding: 15px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #3a3a3a;
  }
}


.footer {
  display: flex;
  gap: 16px;
  align-items: center;
}

.inputField {
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 3px;
  padding: 8px 12px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
  outline: none;
  width: 100%;
  border: solid 1px #464444 !important;
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  box-shadow: none;
  margin: 0 !important;

  &::placeholder {
    color: #fff;
    font-size: 12px;
  }
}

.inputField2 {
  background-color: #1a1a1a;
  color: #d0e1ff;
  border-radius: 3px;
  padding: 8px 12px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
  outline: none;
  width: 100%;
  border: solid 1px #5e6c8e !important;
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  box-shadow: none;
  margin: 0 !important;

  &::placeholder {
   opacity: 0.3;
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
    font-size: 12px;
  }
}

.inputFieldX {
  background-color: #292929;
  border-radius: 3px;
  padding: 3px 5px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;

  outline: none;
  width: 55px;
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  box-shadow: none;
  margin: 0 !important;
text-align: right;
  &::placeholder {
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    opacity: 0.3;
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
  border-radius: 5px;
  padding: 0 5px;
  border: none !important;
}

.inputFieldX2 {
  background-color: #292929;
  border-radius: 3px;
  padding: 3px 5px !important;
  font-family: 'Proto Mono', monospace;
  font-size: 13px;

  outline: none;
  width: 37px;
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  box-shadow: none;
  margin: 0 !important;
text-align: right;
  &::placeholder {
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    opacity: 0.3;
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
  }
  border-radius: 5px;
  padding: 0 5px;
  border: none !important;
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}

.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.tag {
  background-color: #1a1a1a;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);

}

.deleteTag {
  margin-left: 8px;
  cursor: pointer;
    color: #4cfaff;

  font-size: 20px;
      text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);

}

.deleteTag:hover {
  color: #4dfaff;
}

.saveButton {
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 3px 12px;
  /* text-shadow: rgb(1 255 221) 0px 0px 16px; */
  height: 33px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border: solid 1px #464444;
  transition: background-color 0.3s ease;

}
