

input[type='text']:focus, textarea:focus {
  border: solid 1px #000;
  box-shadow: none;
}

.container {
  .title {
    font-size: 18px;
    margin-top: 20px;

width: 100%;

      display: inline-block; 
      color: #fff; 
  text-shadow: rgb(1 255 221) 0px 0px 16px;
  font-weight: bold;


  }

  .footer {
    display: flex;
    gap: 16px;
  }

  .showFilterLink {
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    color: rgb(246, 174, 45);
  }
}

.customToast {
  font-family: 'Proto Mono', monospace;
  font-size: 13px;
    color: #4cfaff;
    padding-left: 15px;
    text-shadow: 0 0 8px rgba(76, 250, 255, 0.8), 0 0 12px rgba(130, 180, 255, 0.5);
}


.full {

  width: 100%;

}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileCard:last-child {
  margin-bottom: none;
}

.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;
  font-weight: bold;
  color: #ddd;
  text-shadow: 0 0 16px rgba(130, 143, 255, 0.5);
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
  display: flex;
  padding-bottom: 15px;
}

.formContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between input and button */
}






.inputField2 {
  width: 100%;
  border: none;
  padding: 8px 12px;
  font-family: 'Proto Mono', monospace;
  font-size: 12px;
  outline: none;
  color: #d0e1ff;
  border: solid 1px #5e6c8e !important;
  text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6);
  background: #1a1a1a;
padding: 10px !important;
    margin-bottom: 15px;
    /* padding-bottom: 15px; */
    padding: 5px 5px;
border-radius: 3px;
      &::placeholder {
      opacity: 0.3;
    color: #d0e1ff !important; /* Brighter, cooler blue-purple */
    text-shadow: 0 0 8px rgba(130, 180, 255, 0.8), 0 0 16px rgba(130, 180, 255, 0.6); /* Stronger blue-purple glow */
    font-size: 12px;
  }

  
}

.profileCard {
  background-color: #1a1a1a;
  border-radius: 10px;
  padding: 20px 10px 0 10px;
  margin-bottom: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}

.profileCard:first-child {
  margin-top: 15px;
}
.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 1px #fff;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.profileTitle {
  font-size: 13px;
  font-weight: normal;
    color: #7fa6ff; /* Brighter blue-purple */
    text-shadow: 0 0 8px rgba(127, 166, 255, 0.8), 0 0 14px rgba(90, 120, 230, 0.6); /* Stronger blue-purple glow */


}


.tagList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.tag {
  background-color: #1a1a1a;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.deleteTag {
  margin-left: 8px;
  cursor: pointer;
  color: #ff4c4c;
  font-weight: bold;
  font-size: 14px;
}

.deleteTag:hover {
  color: #ff6666;
}

.profileContainer {
  color: #ddd;
  border-radius: 8px;
}

.saveButton {
    width: 100%;
    padding: 10px 10px;
    font-size: 13px;
    color: #1c1c1c;
    background-color: #789fff;
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    font-family: "Proto Mono", monospace;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(100, 150, 255, 0.8), 0 0 6px rgba(150, 200, 255, 0.6); /* Pure blue glow */
    box-shadow: 0 0 10px rgba(100, 150, 255, 0.8), 0 0 16px rgba(150, 200, 255, 0.6); /* Updated shadow */
    transition: background-color 0.3s ease, box-shadow 0.3s ease;


}